import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "item", "article" ]

    copy(event) {
        this.articleTarget.querySelector("img").alt = event.currentTarget.querySelector("img").alt
        this.articleTarget.querySelector("img").src = event.currentTarget.querySelector("img").src
        this.articleTarget.querySelector("h3").textContent = event.currentTarget.querySelector("figcaption").textContent
        this.articleTarget.querySelector("p").textContent = event.currentTarget.querySelector("p").textContent

        Array.from(event.currentTarget.parentElement.children).forEach(sib => sib.classList.remove('selected'))
        event.currentTarget.classList.add('selected')
    }

    connect() {
        console.log("active!");
    }
}

