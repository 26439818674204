// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from "jquery";
require("jquery");

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// import "controllers"
import '../stylesheets/application.scss'

import { Foundation } from 'foundation-sites/js/foundation.core';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu';
import { ResponsiveMenu } from 'foundation-sites/js/foundation.responsiveMenu';
import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle';
import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll';
// import { Orbit } from 'foundation-sites/js/foundation.orbit';
import { Triggers } from 'foundation-sites/js/foundation.util.triggers';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { Motion } from 'foundation-sites/js/foundation.util.motion';
import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard';
import { Timer } from 'foundation-sites/js/foundation.util.timer';
import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader';
import { Touch } from 'foundation-sites/js/foundation.util.touch';

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Splide from "@splidejs/splide"

window.jQuery = jQuery;
window.$ = $;
window.onImagesLoaded = onImagesLoaded;
// window.Splide = Splide;

Rails.start()
ActiveStorage.start()

Foundation.addToJquery($);
Triggers.init($, Foundation);

Foundation.plugin(Reveal, 'Reveal');
Foundation.plugin(DropdownMenu, 'DropdownMenu');
Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');
Foundation.plugin(SmoothScroll, 'SmoothScroll');
Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu');
Foundation.plugin(MediaQuery, 'MediaQuery');
Foundation.plugin(Motion, 'Motion');
// Foundation.plugin(Orbit, 'Orbit');
// Foundation.plugin(Keyboard, 'Keyboard');
// Foundation.plugin(Timer, 'Timer');
Foundation.plugin(Touch, 'Touch');

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

$(document).on('turbo:load', function(){
    console.log("Turbo started!")
    $(document).foundation();

    new Splide( '#references > .splide', {
        type   : 'loop',
        perPage: 1,
        perMove: 1,
        autoplay: true
    } ).mount();
});
