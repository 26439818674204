import {Controller} from "stimulus"
import Splide from "@splidejs/splide"

export default class extends Controller {
    static targets = ["loading", "contacts"]

    renderSplide(mutationsList, observer) {
        console.log("subtree changed!" + mutationsList);
        new Splide('#contacts > turbo-frame > .splide', {
            type: 'loop',
            perPage: 4,
            perMove: 1,
            breakpoints: {
                1920: {
                    perPage: 4,
                },
                1600: {
                    perPage: 3,
                },
                1112: {
                    perPage: 2,
                },
                640: {
                    perPage: 1,
                },
            }
        }).mount();
    }

    connect() {
        console.log("contacted!");
        this.observer = new MutationObserver(this.renderSplide);
        this.observer.observe(this.element, {childList: true});
    }

    disconnect() {
        console.log("dis-contacted!");
        this.observer.disconnect();
    }
}

